<template>
<!-- 巡检任务 -->
<div class="setting_main_bg">
  <div class="task_cont">
    <div class="task_box">
      <div class="btn_group" v-if="roleBtnArray[0].power">
        <div class="iconfont icondaochu settingExport" @click="handleExport"><span>导出</span></div>
      </div>
      <div class="flexAC" :class="roleBtnArray[0].power === true?'ut_top':''">
        <el-input v-model="inspectaskname"  placeholder="请输入搜索内容" class="contInput"></el-input>
        <el-select v-model="intaskstate" placeholder="全部状态" class="utF setSe">
          <el-option
            v-for="(item,index) in taskstateOption"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="intaskwttl" placeholder="是否逾期" class="utF setSe" style="margin: 0 10px;">
          <el-option
            v-for="(item,index) in taskwttlOption"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
          v-model="date"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
        <el-button class="settingReset" @click="handleReset">重置</el-button>
      </div>
      <div class="task_table">
        <el-table
          stripe
          :data="taskList"
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="单位名称"
            prop="companyname"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="进度"
            width="80"
            prop="percentage">
            <template slot-scope="scope">
                <span :class="{ 'state1': scope.row.intaskstate ==='1','state3': scope.row.intaskstate ==='3', 'state4': scope.row.intaskstate ==='4', 'state5': scope.row.intaskstate ==='5'}">
                  {{ scope.row.percentage }}
                </span>
            </template>
          </el-table-column>
          <el-table-column
            label="任务编号"
            prop="intaskcode"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="任务名称"
            prop="inplanname"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="任务时间"
            prop="intaskstime">
          </el-table-column>
          <el-table-column
            label="状态"
            prop="intaskstate">
            <template slot-scope="scope">
              <span :class="{ 'state1': scope.row.intaskstate ==='1','state3': scope.row.intaskstate ==='3', 'state4': scope.row.intaskstate ==='4', 'state5': scope.row.intaskstate ==='5'}">
                {{ scope.row.statename }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="是否停用"
            width="80"
            prop="intaskdise">
            <template slot-scope="scope">
              <span v-if="scope.row.intaskdise == 1">否</span>
              <span v-if="scope.row.intaskdise == 2">是</span>
            </template>
          </el-table-column>
          <el-table-column
            label="是否逾期"
            width="80"
            prop="intaskwttl">
            <template slot-scope="scope">
              <span v-if="scope.row.intaskwttl == 1">否</span>
              <span v-if="scope.row.intaskwttl == 2">是</span>
            </template>
          </el-table-column>
          <el-table-column
            label="完成时间"
            prop="intaskactime"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="巡检人员"
            width="80"
            prop="usercount">
          </el-table-column>
          <el-table-column
            label="巡检点"
            width="80"
            prop="pointcount">
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
              <el-button v-if="scope.row.intaskstate === '3'" type="text" size="small" @click="handleReport(scope.row)">上报详情</el-button>
              <el-button v-if="scope.row.intaskstate !== '3'" type="text" size="small" disabled>上报详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>
  </div>

  <!-- 详情 -->
  <el-dialog title="巡检任务详情" :visible.sync="detailShow" width="800px" center class="gyDialog unit_addDialog">
    <div class="flexSC">
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">巡检计划基本信息</p>
        </div>
        <div class="flexCB">
          <p class="udiIP">任务编号：{{ detailInfo.intaskcode }}</p>
          <p class="udiIP">任务名称：{{ detailInfo.inplanname }}</p>
        </div>
        <div class="flexCB">
          <p class="udiIP">开始时间：{{ detailInfo.intaskstime }}</p>
          <p class="udiIP">结束时间：{{ detailInfo.intasketime }}</p>
        </div>
        <div class="flexCB">
          <p class="udiIP">状态：
            <span v-if="detailInfo.intaskstate == 1">待巡检</span>
            <span v-if="detailInfo.intaskstate == 2">巡检中</span>
            <span v-if="detailInfo.intaskstate == 3">已巡检</span>
          </p>
          <p class="udiIP">是否逾期：
            <span v-if="detailInfo.intaskwttl == 1">未逾期</span>
            <span v-if="detailInfo.intaskwttl == 2">已逾期</span>
          </p>
        </div>
        <div class="flexCB">
          <p class="udiIP">是否停用：
            <span v-if="detailInfo.intaskdise == 1">未停用</span>
            <span v-if="detailInfo.intaskdise == 2">已停用</span>
          </p>
          <p class="udiIP">实际完成时间：{{ detailInfo.intaskactime }}</p>
        </div>
      </div>
    </div>

    <div class="flexSC"  v-if="inplanuser.length != 0">
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">巡检人员</p>
        </div>
        <div style="display: flex;flex-wrap: wrap;">
          <p class="udi_s" v-for="(item, index) in inplanuser" :key="index">巡检人员：{{ item.username }}</p>
        </div>
      </div>
    </div>

    <div class="flexSC" v-if="inplanpoint.length != 0">
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">巡检点</p>
        </div>
        <div style="display: flex;flex-wrap: wrap;">
          <p class="udiS" v-for="(item, index) in inplanpoint" :key="index"><span>巡检点：{{ item.inpointname }}</span></p>
        </div>
      </div>
    </div>

    <div class="flexSC" v-if="equlist.length != 0">
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">巡检设备</p>
        </div>
        <div class="udi_c" v-for="(item, index) in equlist" :key="index">
          <div class="udi_r"><span>{{item.equname}}</span></div>
        </div>
      </div>
    </div>
  </el-dialog>

  <!-- 新版详情  -->
  <el-dialog :visible.sync="showNewShow" width="800px" center class="unit_addDialog">
    <div class="nd_cont flexSC">
      <div class="nd_l">
        <div class="flexAC" ><div class="udi_k"></div><p class="udi_t">巡检任务基本信息</p></div>
        <div class="ne_lInfo">
          <p class="l_itme">单位名称：<span>{{ detailInfo.companyname }}</span></p>
          <p class="l_itme">任务编号：<span>{{ detailInfo.intaskcode }}</span></p>
          <p class="l_itme">任务名称：<span>{{ detailInfo.inplanname }}</span></p>
          <p class="l_itme">任务时间：<span>{{ detailInfo.intaskstime }}</span></p>
          <p class="l_itme">状态：
            <span :class="{ 'state1': detailInfo.intaskstate ==='1','state3': detailInfo.intaskstate ==='3', 'state4': detailInfo.intaskstate ==='4', 'state5': detailInfo.intaskstate ==='5'}">
                {{ detailInfo.statename }}
            </span>
          </p>
          <p class="l_itme">是否逾期：<span v-if="detailInfo.intaskwttl == 1">否</span><span v-if="detailInfo.intaskwttl == 2">是</span></p>
          <p class="l_itme">是否停用：<span v-if="detailInfo.intaskdise == 1">否</span><span v-if="detailInfo.intaskdise == 2">是</span></p>
          <p class="l_itme">完成时间：<span>{{ detailInfo.intaskactime }}</span></p>
          <p class="l_itme">进度：
            <span :class="{ 'state1': detailInfo.intaskstate ==='1','state3': detailInfo.intaskstate ==='3', 'state4': detailInfo.intaskstate ==='4', 'state5': detailInfo.intaskstate ==='5'}">
                {{ detailInfo.percentage }}
            </span>
          </p>
          <p class="l_itme">巡检人员：<span>{{ detailInfo.planuser }}</span></p>
        </div>
      </div>
      <div class="nd_r">
        <div class="ndr_top">
          <div class="flexAC" >
            <div class="udi_k"></div>
            <p class="udi_t">巡检点（<span class="state1">{{ taskCount }}</span>/<span class="state2">{{ taskPoint.length }}</span>）</p>
          </div>
          <div class="top_one">
            <el-collapse accordion class="botCollapse"  v-model="activeName" >
              <el-collapse-item  v-for="(item, index) in taskPoint" :key="index" :name="index">
                <template slot="title">
                  <p class="title_user state1">{{ item.username }} {{ item.statename }} {{ item.inspectime }}</p>
                  <p class="title_name"><i class="iconfont iconround btIco state1"></i>{{ item.inpointname }}</p>
                </template>
                <div class="btc_cont">
                  <p v-for="equ in item.inequlistid" :key="equ.index">{{ equ.equname }}<span>（{{ equ.adderss }}）</span></p>
<!--                  <p>电梯火灾探测器<span>（未央区文景路中段一方中港国际6层605办公区6号工位）</span></p>-->
<!--                  <p>视频联动<span>（二楼西南方向上方）</span></p>-->
                </div>
              </el-collapse-item>
<!--              <el-collapse-item name="2">-->
<!--                <template slot="title">-->
<!--                  <p class="title_user state2">刘军-已巡检 2020.12.24 12:24:24</p>-->
<!--                  <p class="title_name"><i class="iconfont iconround btIco state2"></i>巡检点名称</p>-->
<!--                </template>-->
<!--                <div class="btc_cont">-->
<!--                  <p>智能烟感<span>（二楼西南方向上方）</span></p>-->
<!--                  <p>电梯火灾探测器<span>（未央区文景路中段一方中港国际6层605办公区6号工位）</span></p>-->
<!--                  <p>视频联动<span>（二楼西南方向上方）</span></p>-->
<!--                </div>-->
<!--              </el-collapse-item>-->
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>

  <!-- 上报详情 -->
  <el-dialog :visible.sync="reportShow" width="800px" center class="unit_addDialog">
    <div class="nd_cont flexSC">
      <div class="nd_l" style="width: 40%;">
        <div class="flexAC" ><div class="udi_k"></div><p class="udi_t">巡检任务基本信息</p></div>
        <div class="top_one" style="background: none;padding: 0px;">
          <div class="pointItem" v-for="(item, index) in reportList" :key="index" :class="item.inpointid == reportId?'pointAction':''"
          @click="selectPoint(item)">
            <p class="pointP">{{ item.username }} {{ item.statename }} {{ item.inspectime }}</p>
            <p class="pointName">{{ item.inpointname }}</p>
          </div>
        </div>
      </div>
      <div class="nd_r" style="width: 60%;height: 470px;">
        <div class="flexAC" ><div class="udi_k"></div><p class="udi_t">巡检上报</p></div>
        <div class="bot_cont">
          <div class="imgBox">
            <p class="imgT">上报内容</p>
            <p>{{ reportMark }}</p>
            <p class="imgT" style="margin-top: 20px;">上传图片</p>
            <div class="imgFlex">
              <img v-for="item in reportImg" :key="item.index" :src="item"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>

  <!-- 督办弹窗 -->
  <el-dialog title="督办" :visible.sync="dialogFormVisible" center width="600px" class="gyDialog unit_addDialog" :close-on-click-modal="false"  :before-close="addFromCancel">
    <el-form :model="form" :rules="formRules" ref="form">
      <el-form-item label="督办方式:"  prop="intaskway">
        <el-radio-group v-model="form.intaskway" style="width:550px;">
          <el-radio label="1">平台</el-radio>
          <el-radio label="2">短信</el-radio>
          <el-radio label="3">语音</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="接收人"   prop="dealuser" >
        <el-select v-model="form.dealuser"  placeholder="请选择接收人" style="width:550px;">
          <el-option
            v-for="item in dealuserOptions"
            :key="item.userid"
            :label="item.username"
            :value="item.userid">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="督办说明：" prop="intaskmark">
        <el-input type="textarea" v-model="form.intaskmark" :maxlength="150" placeholder="请输入内容长度最多150字符"></el-input>
      </el-form-item>
    </el-form>
    <div class="flexCE">
      <div class="settingCancel" @click="addFromCancel">取消</div>
      <div class="settingConfirm" @click="AddFromConfirm">确定</div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { getInspectask, getplanDetails, Instaskoversee, onSiteExport, taskUserQuery, taskReport } from '@/http/api/onsite'
import { userQuery } from '@/http/api/user'
export default {
  name: 'onsiteTask',
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      taskList: [],
      date: null,
      inspectaskname: '',
      intaskstate: '',
      taskstateOption: [
        { value: '', label: '全部状态' },
        { label: '待巡检', value: '1' },
        { label: '巡检中', value: '2' },
        { label: '已巡检', value: '3' }
      ],
      intaskwttl: '',
      taskwttlOption: [
        { label: '是否逾期', value: '' },
        { label: '未逾期', value: '1' },
        { label: '已逾期', value: '2' }
      ],
      detailShow: false,
      detailInfo: {},
      inplanuser: [],
      equlist: [],
      inplanpoint: [],
      form: {
        intaskway: '1',
        intaskmark: '',
        intaskcode: '',
        dealuser: ''
      },
      formRules: {
        intaskway: [{ required: true, message: '请选择督办方式', trigger: 'change' },],
        dealuser: [{ required: true, message: '请选择接收人', trigger: 'change' },]
      },
      dealuserOptions: [],
      dialogFormVisible: false,
      roleBtnArray: [],
      showNewShow: false,
      isFinish: true,
      taskPoint: [],
      taskCount: 0,
      activeName: 0,
      reportShow: false,
      reportList: [],
      reportId: '',
      taskReportCode: '',
      reportImg: [],
      reportMark:''
    }
  },
  beforeMount () {
    if (this.roleBtn.length > 0) {
      this.roleBtnArray = this.roleBtn
      // console.log(this.roleBtnArray)
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      let stime = ''
      let etime = ''
      if (this.date != null) {
        stime = this.date[0]
        etime = this.date[1]
      }
      const params = {
        inspectaskname: this.inspectaskname,
        page_index: this.page,
        page_size: this.size,
        intaskacstime: stime,
        intaskacetime: etime,
        intaskstate: this.intaskstate,
        intaskwttl: this.intaskwttl
      }
      getInspectask(params).then(res => {
        if (res.status === '1') {
          this.taskList = res.data.data
          // console.log(this.taskList)
          this.total = res.data.recordcount
        } else {
          this.taskList = []
          this.total = 0
        }
      })
    },

    // 详情
    handleDetail (row) {
      // this.detailShow = true
      this.showNewShow = true
      this.detailInfo = row
      this.activeName = 0
      taskUserQuery({ inplanid: row.inplanid, intaskcode: row.intaskcode }).then(res => {
        if (res.status === '1') {
          this.taskPoint = res.data.planpoint
          this.taskCount = res.data.count
        } else {
          this.taskPoint = []
        }
      })
    },
    // 上报详情
    handleReport (row) {
      this.reportShow = true
      this.taskReportCode = row.intaskcode
      taskUserQuery({ inplanid: row.inplanid, intaskcode: row.intaskcode }).then(res =>{
        if (res.status === '1') {
          if (res.data.planpoint.length > 0) {
            this.reportList = res.data.planpoint
            this.reportId = this.reportList[0].inpointid
            this.getReport()
          }
        }
      })
    },
    getReport () {
      taskReport({ inpointid: this.reportId, intaskcode: this.taskReportCode }).then(res => {
        if (res.status === '1') {
          this.reportMark = res.data.inptmark
          this.reportImg = res.data.inptimg
        }
      })
    },
    selectPoint (item) {
      this.reportId = item.inpointid
      this.getReport()
    },
    // 督办
    handleTask (row) {
      this.dialogFormVisible = true
      this.form = { ...row, intaskway: '1' }
      // 接收人
      userQuery({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.dealuserOptions = res.data.data
        } else {
          this.dealuserOptions = []
        }
      })
    },
    // 提交
    AddFromConfirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        const params = {
          intaskcode: this.form.intaskcode,
          intaskway: this.form.intaskway,
          intaskmark: this.form.intaskmark,
          dealuser: this.form.dealuser
        }
        Instaskoversee(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    addFromCancel () {
      this.dialogFormVisible = false
      this.$refs.form.resetFields()
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.inspectaskname = ''
      this.intaskstate = ''
      this.intaskwttl = ''
      this.date = null
      this.init()
    },
    // 数据导出
    handleExport () {
      let staTime = ''
      let endTime = ''
      if (this.date != null) {
        staTime = this.date[0]
        endTime = this.date[1]
      }
      const params = {
        inspectaskname: this.inspectaskname,
        intaskstate: this.intaskstate,
        intaskwttl: this.intaskwttl,
        intaskacstime: staTime,
        intaskacetime: endTime
      }
      onSiteExport(params).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          window.open(res.data.filepath)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.task_cont{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.task_box{
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}
.ut_top,.task_table {
  margin-top: 20px;
}
.ud_left{
  width: 520px;
}
.udi_p,.udi_s,.udiS,.udiIP{
  width: 100%;
  background: #F6F9FF;
  color: #333333;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.udiIP{
  width: 48%;
}
.udi_s{
  width: 24%;
  margin-right: 1%;
}
.udiS{
  width: 49%;
  margin-right: 1%;
}
.udi_t {
  color: #333333;
  font-size: 18px;
  font-weight: 800;
  margin-left: 10px;
}
.udi_k {
  width: 4px;
  height: 17px;
  background: #196DF7;
  opacity: 1;
}
.udi_c {
  float: left;
  display: flex;
  width: 160px;
  height: 40px;
  background: #F6F9FF;
  margin-left: 10px;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.ud_left{
  width: 700px;
}
.nd_cont{
  width: 100%;
}
.nd_l{
  width: 55%;
  padding-right: 20px;
  box-sizing: border-box;

}
.nd_r{
  width: 45%;
  padding-left: 20px;
  box-sizing: border-box;
  border-left: 2px solid #EBEDF5;
}
.ne_lInfo,.ndr_bot{
  margin-top: 10px;
}
.l_itme{
  width: 100%;
  background: #F6F9FF;
  color: #333333;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.l_itme:last-child{
  margin-bottom: 0px;
}
.ne_lInfo span{
  color: #8291A1;
}
.top_cont,.bot_cont,.top_one{
  width: 100%;
  height: 200px;
  color: #8291A1;
  overflow: auto;
  background: #F6F9FF;
  scrollbar-width: none;/* //火狐兼容 */
  padding: 10px 15px 10px 10px;
  box-sizing: border-box;
  margin-top: 7px;
}
.bot_cont{height: calc(100% - 32px);}
.top_one{
  height: 440px;
}
.top_cont::-webkit-scrollbar,
.top_one::-webkit-scrollbar,
.bot_cont::-webkit-scrollbar{/*滚动条整体样式*/
  width: 2px;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.top_cont::-webkit-scrollbar-thumb,
.top_one::-webkit-scrollbar-thumb,
.bot_cont::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  background: #CADDFE;
}
.top_cont::-webkit-scrollbar-track,
.top_one::-webkit-scrollbar-track,
.bot_cont::-webkit-scrollbar-track { /*滚动条里面轨道*/
  border-radius: 10px;
  background: #EDEDED;
}
.imgFlex{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.imgFlex img{
  width: 85px;
  height: 85px;
  border-radius: 5px;
  margin-right: 16px;
  margin-bottom: 15px;
}
.imgFlex img:nth-child(4n+4){
  margin-right: 0px;
}
.imgT{
  font-size: 15px;
  font-weight: 550;
  color: #333;
  margin-bottom: 10px;
}
.botCollapse >>> div {
  margin-bottom: 12px;
}
.botCollapse, .botCollapse >>> .el-collapse-item__header,
.botCollapse >>> .el-collapse-item__wrap{
  border: none;
  background: none;
}
.botCollapse .btIco, .botCollapse >>> .el-collapse-item__arrow{
  font-size: 15px;
  font-weight: 800;
  margin-right: 6px;
}
.botCollapse >>> .el-collapse-item__header{
  height: 40px;
  font-weight: 800;
  font-size: 14px;
  position: relative;
}
.botCollapse >>> .el-collapse-item__content{
  padding-bottom: 0px;
}
.btc_title p{
  line-height: 20px;
}
.btc_cont{
  padding-left: 21px;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
}
.btc_cont span{
  color: #999;
}
.title_name{
  margin-top: 15px;
  line-height: 25px;
}
.title_user{
  position: absolute;
  top: 0;
  line-height: 15px;
  padding-left: 21px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 600;
}
/*.ne_lInfo .state1, .ndr_top .state1{*/
/*  color: #17AB35;*/
/*}*/
/*.ne_lInfo .state2, .ndr_top .state2, .state1{*/
/*  color: #196DF7;*/
/*}*/
/*.ne_lInfo .state3, .ndr_top .state3{*/
/*  color: #FF944C;*/
/*}*/

.state1, .ne_lInfo .state1 {
  color: #196DF7;
}
.state3, .ne_lInfo .state3 {
  color: #17AB35;
}
.state4, .ne_lInfo .state4 {
  color: #FF944C;
}
.state5, .ne_lInfo .state5 {
  color: #8291A1;
}
.pointItem{
  width: 100%;
  height: 60px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  background: #CADDFE;
  margin-bottom: 10px;
  cursor: pointer;
}
.pointName{
  color: #333;
  line-height: 22px;
}
.pointP{
  font-size: 12px;
}
.pointAction {
  background: #196DF7;
  color: #fff;
}
.pointAction .pointName{
  color: #fff;
}
.pointItem:last-child{
  margin-bottom: 0px;
}
</style>
